import * as React from 'react';

import {
  PkpassPreview,
  FieldClickHandler,
  FieldDnDHandler,
  ImageClickHandler,
  BarcodeClickHandler,
  LogoTextClickHandler,
  BoardingPassIconClickHandler,
} from '../PkpassPreview';
import {
  ImagesConvertor,
  jsonTemplateToPassJson,

} from '../../utils/jsonTemplate';
import { Template } from '../../types';



export const PkpassTemplatePreview = (props: {
  template: Template;
  language: string;
  imagesConvertor: ImagesConvertor;
  scanDisabled?: boolean;
  scale?: number;
  onFieldClick?: FieldClickHandler;
  onFieldDnD?: FieldDnDHandler;
  onImageClick?: ImageClickHandler;
  onBarcodeClick?: BarcodeClickHandler;
  onLogoTextClick?: LogoTextClickHandler;
  onBoardingPassIconClick?: BoardingPassIconClickHandler;
}): JSX.Element => {
  const { template, ...rest } = props;

  const pass = React.useMemo(() => {
    return jsonTemplateToPassJson(template.jsonTemplate, props.language);
  }, [template.jsonTemplate, props.language]);

  const images = React.useMemo(() => {
    return props.imagesConvertor(template.images || {}, props.language);
  }, [props.language, template.images, props.imagesConvertor]);

  return <PkpassPreview pass={pass} images={images} {...rest} />;
};
