import * as React from 'react';

import { usePkpass } from '../usePkpass';
import { ImageTypes, PkpassPassStyleTypes } from '../../../../types/pkpass';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

const useStyles = createUseStyles<
  string,
  { passStyle: PkpassPassStyleTypes; clickable: boolean },
  Theme
>({
  [ImageTypes.logo]: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
    width: ({ theme }) => theme.scale * 148,
    marginLeft: ({ theme }) => theme.scale * 12,
    height: ({ theme }) => theme.scale * 44,
    maxWidth: ({ theme }) => theme.scale * 148,
    maxHeight: ({ theme }) => theme.scale * 44,
    float: 'left',
  },
  [ImageTypes.strip]: {
    width: 320,
    height: ({ theme, passStyle }) =>
      passStyle === PkpassPassStyleTypes.Coupon ||
      passStyle === PkpassPassStyleTypes.StoreCard
        ? theme.scale * 122
        : theme.scale * 84,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  [ImageTypes.icon]: {
    width: ({ theme }) => theme.scale * 29,
    height: ({ theme }) => theme.scale * 29,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
  },
  [ImageTypes.footer]: {
    width: ({ theme }) => theme.scale * 300,
    height: ({ theme }) => theme.scale * 20,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  [ImageTypes.thumbnail]: {
    width: ({ theme }) => theme.scale * 90,
    height: ({ theme }) => theme.scale * 90,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
  },
  [ImageTypes.background]: {
    width: ({ theme }) => theme.scale * 360,
    height: ({ theme }) => theme.scale * 440,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
      opacity: ({ clickable }) => (clickable ? 0.7 : undefined),
    },
  },
});

export interface ImagePreviewProps {
  imageType: ImageTypes;
}

const ImagePreview = (props: ImagePreviewProps): JSX.Element => {
  const { passStyle, language, images, onImageClick } = usePkpass();
  const theme: Theme = useTheme();
  const classes = useStyles({ theme, passStyle, clickable: !!onImageClick });
  const id = images ? images[props.imageType] : undefined;

  let idStr: string | undefined;
  if (id && typeof id != 'string') {
    idStr = id[language];
  } else {
    idStr = id as string;
  }
  if (!idStr) {
    return <div className={classes[props.imageType]}></div>;
  }

  const url = idStr;

  const style: React.CSSProperties = {
    backgroundImage: `url(${url})`,
  };

  return (
    <div
      className={classes.container}
      onClick={
        onImageClick
          ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              onImageClick(props.imageType, event);
            }
          : undefined
      }
    >
      <div style={style} className={classes[props.imageType]} />
    </div>
  );
};

export default ImagePreview;
