import * as React from 'react';

import { usePkpass } from '../usePkpass';

import { createUseStyles } from 'react-jss';
import { PkpassPassStyleTypes } from '../../../../types';
import { Theme, useTheme } from '../../Theme';

const useStyles = createUseStyles<string, {}, Theme>({
  root: {
    width: ({ theme }) => 320 * theme.scale,
    height: ({ theme }) => 450 * theme.scale,
    filter: 'drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2))',
  },

  svg: {
    position: 'absolute',
    width: ({ theme }) => 320 * theme.scale,
    height: ({ theme }) => 450 * theme.scale,
    left: 0,
  },
});

const maskStyle = {
  [PkpassPassStyleTypes.BoardingPass]: {
    mask: "url('#m-boarding')",
  },

  [PkpassPassStyleTypes.Coupon]: {
    mask: "url('#m-coupon')",
  },

  [PkpassPassStyleTypes.EventTicket]: {
    mask: "url('#m-ticket')",
  },

  [PkpassPassStyleTypes.Generic]: {
    mask: "url('#m-round')",
  },

  [PkpassPassStyleTypes.StoreCard]: {
    mask: "url('#m-round')",
  },
};

export default function DesignerSide(
  props: React.PropsWithChildren<unknown>
): JSX.Element {
  const { children } = props;
  const { passStyle, pass } = usePkpass();
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.root}>
      <div className={passStyle}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 320 450'
          className={classes.svg}
        >
          <path
            d='M0 0h320v450H0z'
            style={{
              fill: pass.backgroundColor,
              mask: maskStyle[passStyle].mask,
            }}
          />
        </svg>
        {children}
      </div>
    </div>
  );
}
