import * as React from 'react';
import { createUseStyles } from 'react-jss';

import BackFields from '../fields/BackFields';
import { Theme, useTheme } from '../../Theme';

const useStyles = createUseStyles<string, {}, Theme>({
  root: {
    backgroundColor: '#f3f3f3',
    filter: 'drop-shadow(5px 11px 15px rgba(0, 0, 0, 0.2))',
    padding: ({ theme }) => 14 * theme.scale,
    width: ({ theme }) => 320 * theme.scale,
    height: ({ theme }) => 450 * theme.scale,
  },

  container: {
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    display: 'flex' as const,
    height: ({ theme }) => theme.scale * 80,
    justifyContent: 'center' as const,
    width: '100%',
  },
});

export default function DesignerContent(): JSX.Element {
  const theme: Theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.root}>
      <div className={classes.container} />
      <BackFields />
    </div>
  );
}
