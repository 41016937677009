import { PkpassBarcodeTypes } from '../../../../types';

interface BarcodeDefinition {
  [key: string]: { isSquared: boolean };
}

export const barcodeTypesValues: BarcodeDefinition = {
  [PkpassBarcodeTypes.PKBarcodeFormatAztec]: {
    isSquared: true,
  },
  [PkpassBarcodeTypes.PKBarcodeFormatPDF417]: {
    isSquared: false,
  },
  [PkpassBarcodeTypes.PKBarcodeFormatQR]: {
    isSquared: true,
  },
  [PkpassBarcodeTypes.PKBarcodeFormatCode128]: {
    isSquared: false,
  },
};

export const barcodeTypes = barcodeTypesValues;
