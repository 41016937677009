import * as React from 'react';
import { usePkpass } from '../usePkpass';
import { PkpassFieldsType, PkpassField } from '../../../../types';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';
import BackField from './field/preview/BackField';

const useStyles = createUseStyles<string, { inPhone: boolean }, Theme>({
  root: {
    backgroundColor: 'white',
    borderRadius: ({ theme }) => theme.scale * 10,
    paddingLeft: ({ theme }) => theme.scale * 10,
    paddingRight: ({ theme }) => theme.scale * 10,
  },
  backfields: {
    height: ({ theme, inPhone }) => theme.scale * (inPhone ? 580 : 450 - 100),
    overflow: 'auto',
    width: '100%',
  },
});

const BackFields = (props: { inPhone?: boolean }): JSX.Element => {
  const { passStyle, pass, onFieldDnD } = usePkpass();
  const theme = useTheme();
  const classes = useStyles({ theme, inPhone: props.inPhone });
  const [dragOver, setDragOver] = React.useState(false);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedIndex = Number(event.dataTransfer.getData('droppedIndex'));
    onFieldDnD(
      PkpassFieldsType.BackFields,
      0,
      PkpassFieldsType.BackFields,
      droppedIndex
    );
    setDragOver(false);
  };

  const fields = pass[passStyle].backFields || [];

  const style = dragOver ? theme.dragOver : {};

  return (
    <div className={classes.root}>
      <div
        className={classes.backfields}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        style={style}
      >
        {fields.map((field: PkpassField, index: number) => {
          return (
            <BackField
              key={field.key}
              fieldsType={PkpassFieldsType.BackFields}
              fieldIndex={index}
            />
          );
        })}
      </div>
    </div>
  );
};
export default BackFields;
