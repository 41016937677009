import * as React from 'react';
import HeaderFields from '../fields/HeaderFields';
import { usePkpass } from '../usePkpass';
import { ImageTypes } from '../../../../types';
import ImagePreview from '../images/ImagePreview';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

export const useStyles = createUseStyles<
  string,
  { clickableLogoText: boolean },
  Theme
>({
  logoTextHeaderFields: {
    display: 'flex',
    flexDirection: 'row',
    width: ({ theme }) => 320 * theme.scale,
    marginBottom: ({ theme }) => 12 * theme.scale,
  },
  logoContainer: {
    //padding: ({ theme }) => `0 ${12 * theme.scale}`,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: ({ theme }) => theme.scale * 160,
  },
  logoTextContainer: {
    padding: ({ theme }) => theme.scale * 5,
    paddingLeft: ({ theme }) => theme.scale * 12,
    color: ({ theme }) => theme.foregroundColor,
    float: 'left',
    fontWeight: 'bold',
    height: ({ theme }) => theme.scale * 25,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: ({ theme }) => 22 * theme.scale,
    '&:hover': {
      cursor: ({ clickableLogoText }) =>
        clickableLogoText ? 'pointer' : undefined,
      opacity: ({ clickableLogoText }) => (clickableLogoText ? 0.7 : undefined),
    },
  },
  headerFieldsContainer: {
    position: 'absolute',
    left: '50%',
    width: ({ theme }) => theme.scale * 160,
    maxWidth: ({ theme }) => theme.scale * 160,
  },
});

export default function LogoTextHeaderFields(): JSX.Element {
  const { pass, language, onLogoTextClick, images } = usePkpass();
  const theme = useTheme();
  const classes = useStyles({ theme, clickableLogoText: !!onLogoTextClick });

  let value = pass.logoText;
  if (value && typeof value !== 'string') {
    value = value[language];
  }

  return (
    <div className={classes.logoTextHeaderFields}>
      <div className={classes.logoContainer}>
        {images[ImageTypes.logo] && (
          <ImagePreview imageType={ImageTypes.logo} />
        )}
        <div className={classes.logoTextContainer} onClick={onLogoTextClick}>
          {value}
        </div>
      </div>
      <div className={classes.headerFieldsContainer}>
        <HeaderFields />
      </div>
    </div>
  );
}
