import * as React from 'react';

import { getPassStyle } from './helpers/passStyle';
import { PkpassImages, Pkpass, PkpassPassStyleTypes } from '../../../types';
import {
  FieldClickHandler,
  ImageClickHandler,
  BarcodeClickHandler,
  FieldDnDHandler,
  LogoTextClickHandler,
  BoardingPassIconClickHandler,
} from '../types';

export type PkpassContextInterface = {
  pass: Pkpass;
  passStyle: PkpassPassStyleTypes;
  images: PkpassImages;
  language: string;
  scanDisabled: boolean;
  onFieldClick?: FieldClickHandler;
  onFieldDnD?: FieldDnDHandler;
  onImageClick?: ImageClickHandler;
  onBarcodeClick?: BarcodeClickHandler;
  onLogoTextClick?: LogoTextClickHandler;
  onBoardingPassIconClick?: BoardingPassIconClickHandler;
};

export const PkpassContext = React.createContext<PkpassContextInterface>({
  pass: {} as Pkpass,
  passStyle: PkpassPassStyleTypes.Generic,
  images: {},
  language: 'en',
  scanDisabled: false,
});

export function PkpassContextProvider(
  props: React.PropsWithChildren<{
    pass: Pkpass;
    images: PkpassImages;
    scanDisabled?: boolean;
    scale?: number;
    onFieldDnD?: FieldDnDHandler;
    onFieldClick?: FieldClickHandler;
    onImageClick?: ImageClickHandler;
    onBarcodeClick?: BarcodeClickHandler;
    onLogoTextClick?: LogoTextClickHandler;
    onBoardingPassIconClick?: BoardingPassIconClickHandler;
    language?: string;
  }>
): JSX.Element {
  const [state, setState] = React.useState<PkpassContextInterface>({
    passStyle: getPassStyle(props.pass),
    pass: props.pass,
    images: props.images,
    language: props.language || 'en',
    scanDisabled: props.scanDisabled === true,
  });

  React.useEffect(() => {
    setState({
      passStyle: getPassStyle(props.pass),
      pass: props.pass,
      images: props.images,
      language: props.language || 'en',
      scanDisabled: props.scanDisabled === true,
    });
  }, [props.pass, props.images, props.language, props.scanDisabled]);

  const context = {
    ...state,
    onFieldDnD: props.onFieldDnD,
    onFieldClick: props.onFieldClick,
    onImageClick: props.onImageClick,
    onBarcodeClick: props.onBarcodeClick,
    onLogoTextClick: props.onLogoTextClick,
    onBoardingPassIconClick: props.onBoardingPassIconClick,
  };

  return (
    <PkpassContext.Provider value={context}>
      {props.children}
    </PkpassContext.Provider>
  );
}
