import * as React from 'react';

import Barcodes from '../barcode/Barcodes';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import PrimaryFields from '../fields/PrimaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import { ImageTypes } from '../../../../types';
import ImagePreview from '../images/ImagePreview';
import { useStyles } from './styles';
import { useTheme } from '../../Theme';

const EventTicket = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <LogoLogoTextHeaderFields />
      <ImagePreview imageType={ImageTypes.strip} />
      <div className={classes.primaryFieldContainer}>
        <PrimaryFields />
      </div>
      <SecondaryFields />
      <AuxiliaryFields />
      <Barcodes />
    </>
  );
};

export default EventTicket;
