import * as React from 'react';
import ImagePreview from '../images/ImagePreview';
import { usePkpass } from '../usePkpass';

import { barcodeTypes, barcodeTypesValues } from '../helpers/barcode';
import Barcode from './Barcode';
import { ImageTypes, PkpassBarcodeTypes } from '../../../../types';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

export const useStyles = createUseStyles<
  string,
  { pass: any; clickable: boolean },
  Theme
>({
  root: {
    bottom: ({ pass, theme }) => {
      const barcodeTypeValue =
        pass.barcodes && pass.barcodes[0] && pass.barcodes[0].format;
      let bottomPosition = 12;
      if (
        barcodeTypes[barcodeTypeValue] &&
        !barcodeTypes[barcodeTypeValue].isSquared
      ) {
        bottomPosition = 30;
      }
      return bottomPosition * theme.scale;
    },
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },

  footerImageContainer: {
    bottom: '100%',
    left: ({ theme }) => theme.scale * 10,
    position: 'absolute',
    right: ({ theme }) => theme.scale * 10,
  },

  barcodeContainer: {
    borderRadius: ({ theme }) => theme.scale * 5,
    backgroundColor: 'white',
    display: 'inline-block',
    maxWidth: ({ theme }) => theme.scale * 250,
    paddingBottom: ({ theme }) => theme.scale * 5,
    paddingLeft: ({ theme }) => theme.scale * 10,
    paddingRight: ({ theme }) => theme.scale * 10,
    paddingTop: ({ theme }) => theme.scale * 10,
    position: 'relative',
    '&:hover': {
      cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
      opacity: ({ clickable }) => (clickable ? 0.7 : undefined),
    },
  },
  altTextContainer: {
    color: 'black',
    paddingTop: ({ theme }) => theme.scale * 5,
    fontSize: ({ theme }) => theme.scale * 10,
    margin: 'auto',
    maxWidth: ({ theme }) => theme.scale * 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default function Barcodes(): JSX.Element {
  const {
    pass,
    passStyle,
    language,
    scanDisabled,
    onBarcodeClick,
  } = usePkpass();
  const theme = useTheme();
  const classes = useStyles({ pass, theme, clickable: !!onBarcodeClick });

  if (!pass.barcodes || !pass.barcodes[0]) {
    return <div></div>;
  }

  const barcodeTypeValue = pass.barcodes[0].format as PkpassBarcodeTypes;
  const barcodes = pass.barcodes;
  const altText = barcodes[0].altText;
  let value = barcodes[0].message;

  if (value && typeof value != 'string') {
    value = value[language];
  }

  return (
    <div className={classes.root}>
      {passStyle === 'boardingPass' && (
        <div className={classes.footerImageContainer}>
          <ImagePreview imageType={ImageTypes.footer} />
        </div>
      )}
      <div className={classes.barcodeContainer} onClick={onBarcodeClick}>
        {value && barcodeTypesValues && (
          <Barcode
            value={value}
            barcodeType={barcodeTypeValue}
            disabled={scanDisabled}
          />
        )}
        <div key='alt-text' className={classes.altTextContainer}>
          {altText}
        </div>
      </div>
    </div>
  );
}
