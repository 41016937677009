import * as React from 'react';

import { usePkpass } from '../../../usePkpass';
import { PkpassField, PkpassPassStyleTypes } from '../../../../../../types';
import { getValueValue } from './FieldPreviewValue';
import { getLabelValue } from './FieldPreviewLabel';
import { useTheme, Theme } from '../../../../Theme';

interface Props {
  values: PkpassField;
}

export const FieldPreviewPrimary = (props: Props): JSX.Element => {
  const { pass, language, passStyle } = usePkpass();
  const theme: Theme = useTheme();
  const { values } = props;
  if (passStyle === PkpassPassStyleTypes.EventTicket) {
    return (
      <div
        style={{
          color: pass.foregroundColor,
          maxWidth: theme.scale * 290,
          verticalAlign: 'middle',
        }}
      >
        <div
          style={{
            color: 'white',
            fontSize: theme.scale * 40,
            height: theme.scale * 50,
            lineHeight: `${theme.scale * 40}px`,
            marginTop: theme.scale * 20,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getValueValue(values, language)}
        </div>
      </div>
    );
  }

  if (
    passStyle === PkpassPassStyleTypes.StoreCard ||
    passStyle === PkpassPassStyleTypes.Coupon
  ) {
    return (
      <div
        style={{
          color: pass.foregroundColor,
          maxWidth: theme.scale * 290,
        }}
      >
        <div
          style={{
            color: 'white',
            fontSize: theme.scale * 38,
            height: theme.scale * 40,
            lineHeight: `${theme.scale * 38}px`,
            marginTop: theme.scale * 30,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getValueValue(values, language) || <span>&nbsp;</span>}
        </div>
        <div
          style={{
            color: 'white',
            fontSize: theme.scale * 15,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          }}
        >
          {getLabelValue(values, language)}
        </div>
      </div>
    );
  }

  if (passStyle === PkpassPassStyleTypes.BoardingPass) {
    return (
      <div
        style={{
          color: pass.foregroundColor,
        }}
      >
        <div
          style={{
            color: pass.labelColor,
            fontSize: theme.scale * 10,
            height: `${theme.scale * 1.3}em`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          }}
        >
          {getLabelValue(values, language)}
        </div>
        <div
          style={{
            color: pass.foregroundColor,
            fontSize: theme.scale * 30,
            height: theme.scale * 34,
            lineHeight: `${theme.scale * 30}px`,
            marginTop: theme.scale * 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getValueValue(values, language)}
        </div>
      </div>
    );
  }

  if (passStyle === PkpassPassStyleTypes.Generic) {
    return (
      <div
        style={{
          color: pass.foregroundColor,
          maxWidth: 200,
        }}
      >
        <div
          style={{
            color: pass.labelColor,
            fontSize: 10,
            height: '1.3em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          }}
        >
          {getLabelValue(values, language)}
        </div>
        <div
          style={{
            color: pass.foregroundColor,
            fontSize: 30,
            height: 34,
            lineHeight: '30px',
            marginTop: 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getValueValue(values, language)}
        </div>
      </div>
    );
  }

  return null;
};
