import * as React from 'react';

import Barcodes from '../barcode/Barcodes';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import PrimaryFields from '../fields/PrimaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import ImagePreview from '../images/ImagePreview';
import { ImageTypes } from '../../../../types';
import { Theme, useTheme } from '../../Theme';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles<string, {}, Theme>({
  primaryFieldThumbnailContainer: {
    display: 'table',
    //  paddingLeft: ({ theme }) => theme.scale * 12,
    //  paddingRight: ({ theme }) => theme.scale * 12,
    width: '100%',
  },
  primaryFieldContainer: {
    display: 'table-cell',
    width: ({ theme }) => theme.scale * 210,
    maxWidth: ({ theme }) => theme.scale * 210,
    verticalAlign: 'top',
  },
  thumbnailContainer: {
    display: 'table-cell',
  },
});

export default function Generic(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <>
      <LogoLogoTextHeaderFields />
      <div className={classes.primaryFieldThumbnailContainer}>
        <div className={classes.primaryFieldContainer}>
          <PrimaryFields />
        </div>
        <div className={classes.thumbnailContainer}>
          <ImagePreview imageType={ImageTypes.thumbnail} />
        </div>
      </div>
      <SecondaryFields />
      <AuxiliaryFields />
      <Barcodes />
    </>
  );
}
