import * as React from 'react';

import PassStyle from '../passStyle/PassStyle';
import DesignerSide from './DesignerSide';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

export const useStyles = createUseStyles<string, {}, Theme>({
  root: {
    position: 'absolute',
    width: ({ theme }) => 320 * theme.scale,
    height: ({ theme }) => 450 * theme.scale,
  },
});

export default function DesignerFrontSide(): JSX.Element {
  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <DesignerSide>
      <div className={classes.root}>
        <PassStyle />
      </div>
    </DesignerSide>
  );
}
