import * as React from 'react';
import bwipjs from 'bwip-js';
import { barcodeTypesValues } from '../helpers/barcode';
import { PkpassBarcodeTypes } from '../../../../types';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

export const useStyles = createUseStyles<string, {}, Theme>({
  squared: {
    width: ({ theme }) => 110 * theme.scale,
    height: ({ theme }) => 110 * theme.scale,
  },
  rectangle: {
    width: ({ theme }) => 230 * theme.scale,
    height: ({ theme }) => 45 * theme.scale,
  },
});

const Barcode = (props: {
  barcodeType: PkpassBarcodeTypes;
  value: string;
  disabled: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const canvas = React.useRef(null);
  React.useEffect(() => {
    if (canvas.current) {
      try {
        let bcid = 'qrcode';
        const size = barcodeTypesValues[props.barcodeType].isSquared
          ? { width: theme.scale * 100, height: theme.scale * 100 }
          : { width: theme.scale * 230, height: theme.scale * 45 };
        switch (props.barcodeType) {
          case PkpassBarcodeTypes.PKBarcodeFormatQR:
            bcid = 'qrcode'; // Barcode type
            break;

          case PkpassBarcodeTypes.PKBarcodeFormatAztec:
            bcid = 'azteccode';
            break;

          case PkpassBarcodeTypes.PKBarcodeFormatPDF417:
            bcid = 'pdf417';
            break;
          case PkpassBarcodeTypes.PKBarcodeFormatCode128:
            bcid = 'code128';
            break;
        }

        bwipjs.toCanvas(canvas.current, {
          ...size,
          //padding: 3 * theme.scale,
          bcid, // Barcode type
          text: props.value, // Text to encode
          scale: 1, // 3x scaling factor
          textxalign: 'center', // Always good to set this
          barcolor: props.disabled ? 'eeeeee' : '000000',
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [props.barcodeType, props.value, canvas, props.disabled, theme.scale]);
  return (
    <canvas
      ref={canvas}
      className={
        barcodeTypesValues[props.barcodeType].isSquared
          ? classes.squared
          : classes.rectangle
      }
    />
  );
};

export default Barcode;
