import * as React from 'react';

import { isObject, isDate, isNumber } from 'lodash';

import FieldPreviewCurrency from './types/FieldPreviewValueCurrency';
import FieldPreviewDate from './types/FieldPreviewValueDate';
import FieldPreviewNumber from './types/FieldPreviewValueNumber';
import { usePkpass } from '../../../usePkpass';
import {
  PkpassField,
  PkpassFieldsType,
  PkpassPassStyleTypes,
  Pkpass,
} from '../../../../../../types';
import { Theme, useTheme } from '../../../../Theme';
import { createUseStyles } from 'react-jss';

interface Props {
  field: PkpassField;
  fieldsType: PkpassFieldsType;
}

export enum PkpassFieldTypes {
  Number = 'number',
  Date = 'date',
  Currency = 'currency',
  Text = 'text',
}

export const getFieldType = (field: PkpassField): PkpassFieldTypes => {
  if (field.currencyCode) {
    return PkpassFieldTypes.Currency;
  }
  if (field.numberStyle) {
    return PkpassFieldTypes.Number;
  }
  if (field.dateStyle || field.timeStyle) {
    return PkpassFieldTypes.Date;
  }
  return PkpassFieldTypes.Text;
};

export const useStyles = createUseStyles<
  string,
  { foregroundColor: string; fontSize: number },
  Theme
>({
  backfield: {
    color: '#7d7d7d',
    marginBottom: ({ theme }) => theme.scale * 10,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    fontSize: ({ theme }) => theme.scale * 16,
  },
  field: {
    color: ({ foregroundColor }) => foregroundColor,
    fontSize: ({ fontSize, theme }) => `${fontSize * theme.scale}%`,
    height: ({ theme }) => theme.scale * 26,
    lineHeight: ({ theme }) => `${theme.scale * 22}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const FieldPreviewValue = (props: Props): JSX.Element => {
  const { field, fieldsType } = props;
  const { passStyle, pass, language } = usePkpass();
  const theme: Theme = useTheme();

  const classes = useStyles({
    theme,
    foregroundColor: pass.foregroundColor,
    fontSize: getFontSize(field, fieldsType, pass, passStyle, language),
  });

  const val = getValueValue(field, language);

  let content =
    PkpassFieldsType.BackFields === fieldsType ? (
      <span dangerouslySetInnerHTML={{ __html: `${val}` }} />
    ) : (
      <span>{val}</span>
    );

  const fieldType = getFieldType(field);

  if (fieldType === PkpassFieldTypes.Date) {
    content = (
      <FieldPreviewDate
        value={`${val}`}
        dateStyle={field.dateStyle}
        timeStyle={field.timeStyle}
      />
    );
  }

  if (fieldType === PkpassFieldTypes.Number && isNumber(val)) {
    content = <FieldPreviewNumber value={val} style={field.numberStyle} />;
  }

  if (fieldType === PkpassFieldTypes.Currency && isNumber(val)) {
    content = (
      <FieldPreviewCurrency
        language={language}
        value={val}
        currency={field.currencyCode}
      />
    );
  }

  return (
    <div
      className={
        PkpassFieldsType.BackFields === fieldsType
          ? classes.backfield
          : classes.field
      }
    >
      {content}
    </div>
  );
};

export const getValue = (
  field: PkpassField
): string | number | Date | undefined => {
  return field.attributedValue || field.value;
};

export const isLocalizedValue = (field: PkpassField): boolean => {
  return isObject(field.attributedValue || field.value);
};

export const getValueValue = (
  field: PkpassField,
  language: string
): string | number | Date => {
  const v = getValue(field);

  if (isObject(v) && !isDate(v)) {
    return v[language];
  }
  return v;
};

const getFieldValueLenght = (f: PkpassField, language: string): number => {
  if (f.value) {
    const v = f.value;
    const value = isObject(v) && !isDate(v) ? `${v[language]}` : `${v}`;
    if (value) {
      return `${value}`.length;
    }
  }
  return 0;
};

// TODO - don't use chars but real width of rendered element.
const getFontSize = (
  f: PkpassField,
  fieldsType: PkpassFieldsType,
  pass: Pkpass,
  passStyle: PkpassPassStyleTypes,
  language: string
): number => {
  if (PkpassFieldsType.BackFields === fieldsType) {
    return 16;
  }

  const MAX_FONT_SIZE = 125;
  const MIN_FONT_SIZE = 75;
  const MAX_CHARS_IN_FIELD = 8;
  let valueCount = 0;

  if (
    passStyle === PkpassPassStyleTypes.Coupon ||
    passStyle === PkpassPassStyleTypes.StoreCard
  ) {
    const MAX_COUNT = 4;

    const secondaryFields = pass[passStyle].secondaryFields || [];
    const auxiliaryFields = pass[passStyle].auxiliaryFields || [];

    const totalCount = auxiliaryFields.length + secondaryFields.length;

    if (totalCount) {
      secondaryFields.forEach((f: PkpassField) => {
        valueCount += getFieldValueLenght(f, language);
      });
      auxiliaryFields.forEach((f: PkpassField) => {
        valueCount += getFieldValueLenght(f, language);
      });
    }

    return Math.min(
      MAX_FONT_SIZE,
      Math.max(
        ((MAX_CHARS_IN_FIELD * MAX_COUNT) / valueCount) * 100,
        MIN_FONT_SIZE
      )
    );
  }
  const fields = pass[passStyle][fieldsType] || [];

  const fieldSize = fields.length || 0;
  if (fieldSize) {
    fields.forEach((f: PkpassField) => {
      valueCount += getFieldValueLenght(f, language);
    });
  }
  return Math.min(
    MAX_FONT_SIZE,
    Math.max(
      ((MAX_CHARS_IN_FIELD * fields.length) / valueCount) * 100,
      MIN_FONT_SIZE
    )
  );
};
