import * as React from 'react';

import Barcodes from '../barcode/Barcodes';
import PrimaryFields from '../fields/PrimaryFields';
import SecondaryAndAuxiliaryFields from '../fields/SecondaryAndAuxiliaryFields';

import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import ImagePreview from '../images/ImagePreview';
import { useStyles } from './styles';
import { ImageTypes } from '../../../../types';
import { useTheme } from '../../Theme';

export default function Coupon(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <LogoLogoTextHeaderFields />
      <ImagePreview imageType={ImageTypes.strip} />
      <div className={classes.primaryFieldContainer}>
        <PrimaryFields />
      </div>
      <SecondaryAndAuxiliaryFields />
      <Barcodes />
    </>
  );
}
