import { PkpassTextAlignTypes } from '../../../../../../types';

export const getTextAlign = (
  textAlignment: PkpassTextAlignTypes | undefined
):
  | 'start'
  | 'end'
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'match-parent'
  | undefined => {
  switch (textAlignment) {
    case PkpassTextAlignTypes.PKTextAlignmentLeft:
      return 'left';
    case PkpassTextAlignTypes.PKTextAlignmentCenter:
      return 'center';
    case PkpassTextAlignTypes.PKTextAlignmentRight:
      return 'right';
    case PkpassTextAlignTypes.PKTextAlignmentNatural:
      return undefined;
    default:
      return 'left';
  }
};
