import { JsonTemplate } from '../types';
import { isObject, has, get, isString } from 'lodash';
import {
  PkpassImages,
  imageTypes,
  ImageReferenceMap,
  TranslatedImageReference,
  Pkpass,
} from '../types';

const _convert = (input: unknown, language: string): unknown => {
  if (isObject(input) && has(input, 'type')) {
    const type = get(input, 'type');
    switch (type) {
      case 'object': {
        const inputProps = get(input, 'properties');
        const props: Record<string, unknown> = {};
        Object.keys(inputProps).forEach((p: string) => {
          props[p] = _convert(inputProps[p], language);
        });
        return props;
      }
      case 'array': {
        const inputItems = get(input, 'items');
        const items: unknown[] = [];
        inputItems.forEach((p: unknown) => {
          items.push(_convert(p, language));
        });
        return items;
      }

      case 'string': {
        const value = get(input, 'value');
        if (isString(value)) {
          return value;
        }
        return value && value[language] || "";
      }
      // TODO - check placeholders
      default: {
        return get(input, 'value');
      }
    }
  }

  return input;
};

export const jsonTemplateToPassJson = (
  jsonTemplate: JsonTemplate,
  language: string
): Pkpass => {
  const props: Record<string, unknown> = {};
  Object.keys(jsonTemplate).forEach((p: string) => {
    props[p] = _convert(get(jsonTemplate, p), language);
  });
  return props;
};

const _imageToUrl = (id: string, imageBaseUrl: string): string => {
  if (id.startsWith("http://" || "https://")){
    return id;
  }
  const url = `${imageBaseUrl}/${id.substring(0, 2)}/${id.substring(2)}.png`;
  return url;
};

export const imagesIdtoURLConvertor = (
  images: ImageReferenceMap,
  imageBaseUrl = 'https://yrps.s3.amazonaws.com/img',
  language?: string
): PkpassImages => {
  const res: PkpassImages = {};
  for (const imageType of imageTypes) {
    if (images[imageType]) {
      if (isObject(images[imageType])) {
        const t: TranslatedImageReference = images[
          imageType
        ] as TranslatedImageReference;
        const i: TranslatedImageReference = {};
        let firstLang;
        Object.keys(t)
          .sort()
          .forEach((lang: string, index: number) => {
            if (index === 0) {
              firstLang = lang;
            }
            const v = t[lang] as string;
            if (v) {
              i[lang] = _imageToUrl(v as string, imageBaseUrl);
            }
          });

        res[imageType] =
          i[language] || i['en'] || (firstLang ? i[firstLang] : undefined);
      }
      if (isString(images[imageType])) {
        res[imageType] = _imageToUrl(images[imageType] as string, imageBaseUrl);
      }
    }
  }

  return res;
};


export type ImagesConvertor = (
  images: ImageReferenceMap,
  language?: string
) => PkpassImages;

export const imagesURLanguageConvertor = (
  images: ImageReferenceMap,
  language?: string
): PkpassImages => {
  const res: PkpassImages = {};
  for (const imageType of imageTypes) {
    if (images[imageType]) {
      if (isObject(images[imageType])) {
        const t: TranslatedImageReference = images[
          imageType
        ] as TranslatedImageReference;
        const i: TranslatedImageReference = {};
        let firstLang;
        Object.keys(t)
          .sort()
          .forEach((lang: string, index: number) => {
            if (index === 0) {
              firstLang = lang;
            }
            const v = t[lang] as string;
            if (v) {
              i[lang] = v
            }
          });

        res[imageType] =
          i[language] || i['en'] || (firstLang ? i[firstLang] : undefined);
      }
      if (isString(images[imageType])) {
        res[imageType] = images[imageType] as string;
      }
    }
  }

  return res;
};
