import * as React from 'react';
import Barcodes from '../barcode/Barcodes';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import { usePkpass } from '../usePkpass';
import BoardingPassIcon from './BoardingPassIcon';
import { PkpassField, PkpassFieldsType } from '../../../../types';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';
import Field from '../fields/field/preview/Field';

export const useStyles = createUseStyles<string, {clickable: boolean}, Theme>({
  containerBoardingPass: {
    display: 'table',
    height: ({ theme }) => theme.scale * 70,
    tableLayout: 'fixed',
    width: ({ theme }) => theme.scale * 320,
  },
  fieldContainer: {
    display: 'table-cell',
    verticalAlign: 'top',
  },
  iconContainer: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: ({ theme }) => theme.scale * 70,
    '&:hover': {
      cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
      opacity: ({ clickable }) => (clickable ? 0.7 : undefined),
    },
  },
});

const BoardingPass: React.FunctionComponent = () => {
  const { pass, onBoardingPassIconClick } = usePkpass();

  const theme = useTheme();
  const classes = useStyles({ theme, clickable: !!onBoardingPassIconClick });

  const primaryFields: PkpassField[] = pass.boardingPass.primaryFields || [];

  return (
    <>
      <LogoLogoTextHeaderFields />
      <div className={classes.containerBoardingPass}>
        <div className={classes.fieldContainer}>
          {primaryFields.length > 0 && (
            <Field
              fieldsType={PkpassFieldsType.PrimaryFields}
              fieldIndex={0}
              align='left'
            />
          )}
        </div>
        <div
          className={classes.iconContainer}
          onClick={onBoardingPassIconClick}
        >
          <BoardingPassIcon />
        </div>
        <div className={classes.fieldContainer}>
          {primaryFields.length > 1 && (
            <Field
              fieldsType={PkpassFieldsType.PrimaryFields}
              fieldIndex={1}
              align='right'
            />
          )}
        </div>
      </div>
      <div>
        <AuxiliaryFields />
        <SecondaryFields />
      </div>
      <Barcodes />
    </>
  );
};

export default BoardingPass;
