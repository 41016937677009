import * as React from 'react';

import { usePkpass } from '../usePkpass';
import { PkpassFieldsType, PkpassField } from '../../../../types';
import Field from './field/preview/Field';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

interface Props {
  fieldsType: PkpassFieldsType;
}

const useStyles = createUseStyles<
  string,
  { fieldsType: PkpassFieldsType },
  Theme
>({
  fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ fieldsType }) =>
      fieldsType === PkpassFieldsType.HeaderFields
        ? 'flex-end'
        : 'space-between',
    minHeight: ({ theme }) => theme.scale * 40,
    paddingBottom: ({ theme }) => theme.scale * 10,
    // direction: ({ fieldsType }) =>
    //   fieldsType === PkpassFieldsType.HeaderFields ? 'rtl' : undefined,
    width: ({ fieldsType }) =>
      fieldsType === PkpassFieldsType.PrimaryFields ? '100%' : undefined,
  },
});

const Fields = (props: Props): JSX.Element => {
  const { passStyle, pass, onFieldDnD } = usePkpass();
  const { fieldsType } = props;

  const [dragOver, setDragOver] = React.useState(false);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedIndex = Number(event.dataTransfer.getData('droppedIndex'));
    const droppedFieldsType = event.dataTransfer.getData(
      'droppedFieldsType'
    ) as PkpassFieldsType;
    onFieldDnD(fieldsType, 0, droppedFieldsType, droppedIndex);
    setDragOver(false);
  };

  const theme: Theme = useTheme();
  const classes = useStyles({ theme, fieldsType });

  let fields = pass[passStyle][fieldsType] || [];
  if (fieldsType === PkpassFieldsType.HeaderFields) {
    fields = fields.reverse();
  }

  return (
    <div
      className={classes.fields}
      onDrop={onFieldDnD ? onDrop : undefined}
      onDragOver={onFieldDnD ? onDragOver : undefined}
      onDragLeave={onFieldDnD ? onDragLeave : undefined}
      style={dragOver ? theme.dragOver : undefined}
    >
      {fields.map((field: PkpassField, i: number) => {
        const index =
          fieldsType === PkpassFieldsType.HeaderFields
            ? fields.length - i - 1
            : i;
        return (
          <Field key={field.key} fieldsType={fieldsType} fieldIndex={index} />
        );
      })}
    </div>
  );
};
export default Fields;
