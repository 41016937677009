import * as React from 'react';

import { FormattedNumber, IntlProvider } from 'react-intl';
import { PkpassNumberStyleTypes } from '../../../../../../../types';
import { usePkpass } from '../../../../usePkpass';

const getStyle = (
  style: PkpassNumberStyleTypes
): 'decimal' | 'currency' | 'percent' | 'unit' | undefined => {
  switch (style) {
    case PkpassNumberStyleTypes.PKNumberStylePercent:
      return 'percent';
    case PkpassNumberStyleTypes.PKNumberStyleDecimal:
    default:
      return 'decimal';
  }
};

interface Props {
  value: string | number;
  style: PkpassNumberStyleTypes;
}

export default function FieldPreviewNumber(props: Props): JSX.Element {
  const { language } = usePkpass();
  const { value, style } = props;

  if (style === PkpassNumberStyleTypes.PKNumberStyleSpellOut) {
    return <span>{/* toWords(Number(value)) */}</span>;
  }

  if (style === PkpassNumberStyleTypes.PKNumberStyleScientific) {
    return (
      <span>
        {Number(value).toExponential().replace(/\+/g, '').toUpperCase()}
      </span>
    );
  }
  const options = {
    style: getStyle(style),
    value: Number(value),
  };
  return (
    <IntlProvider locale={language}>
      <FormattedNumber {...options} />
    </IntlProvider>
  );
}
