import * as React from 'react';

import { get as _get } from 'lodash';

import { FieldPreviewLabel } from './FieldPreviewLabel';
import { PkpassFieldsType, PkpassField, Pkpass } from '../../../../../../types';
import { usePkpass } from '../../../usePkpass';
import { FieldPreviewValue } from './FieldPreviewValue';
import { FieldPreviewPrimary } from './FieldPreviewPrimary';
import { createUseStyles } from 'react-jss';
import { getTextAlign } from './getTextAlign';
import { Theme, useTheme } from '../../../../Theme';

interface Props {
  fieldsType: PkpassFieldsType;
  fieldIndex: number;
  align?: 'left' | 'right';
}

export const useStyles = createUseStyles<
  string,
  { clickable: boolean; pass: Pkpass },
  Theme
>({
  field: {
    padding: ({ theme }) => `${theme.scale * 10}px ${theme.scale * 12}px`,
    '&:hover': {
      cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
      opacity: ({ clickable }) => (clickable ? 0.7 : undefined),
    },
  },
});

const Field = (props: Props): JSX.Element => {
  const { fieldsType, fieldIndex } = props;
  const { pass, passStyle, onFieldClick, onFieldDnD } = usePkpass();
  const [dragOver, setDragOver] = React.useState(false);
  const theme: Theme = useTheme();
  const classes = useStyles({ pass, theme, clickable: !!onFieldClick });

  const fieldsPath = `${passStyle}.${fieldsType}`;
  const fieldPath = `${fieldsPath}.${fieldIndex}`;
  const field: PkpassField = _get(pass, `${fieldPath}`);

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(false);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setDragOver(true);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('droppedFieldsType', fieldsType);
    event.dataTransfer.setData('droppedIndex', `${fieldIndex}`);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedIndex = Number(event.dataTransfer.getData('droppedIndex'));
    const droppedFieldsType = event.dataTransfer.getData(
      'droppedFieldsType'
    ) as PkpassFieldsType;
    onFieldDnD(fieldsType, fieldIndex, droppedFieldsType, droppedIndex);
    setDragOver(false);
  };

  const style = {
    fontSize: 15 * theme.scale,
    minHeight: 26 * theme.scale,
    minWidth: 20 * theme.scale,
    textAlign: props.align || getTextAlign(field.textAlignment),
    zIndex: 100,
  };

  let content = (
    <React.Fragment>
      <FieldPreviewLabel
        key='fieldPreviewLabel'
        field={field}
        fieldsType={fieldsType}
        labelColor={pass.labelColor}
        theme={theme}
      />
      <FieldPreviewValue
        key='fieldPreviewValue'
        field={field}
        fieldsType={fieldsType}
      />
    </React.Fragment>
  );

  if (fieldsType === PkpassFieldsType.PrimaryFields) {
    content = <FieldPreviewPrimary values={field} />;
  }

  return (
    <div
      key={field.key}
      draggable={!!onFieldDnD}
      onDragOver={onFieldDnD ? onDragOver : undefined}
      onDrop={onFieldDnD ? onDrop : undefined}
      onDragStart={onFieldDnD ? onDragStart : undefined}
      onDragLeave={onFieldDnD ? onDragLeave : undefined}
      className={`field ${fieldsType} ${classes.field}`}
      style={{ ...style, ...(dragOver ? theme.dragOver : {}) }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onFieldClick) {
          onFieldClick({ fieldsType, field, fieldIndex }, event);
        }
      }}
    >
      {content}
    </div>
  );
};

export default Field;
