export interface PkpassImages {
  logo?: string;
  icon?: string;
  strip?: string;
  thumbnail?: string;
  footer?: string;
  background?: string;
}

export enum PkpassPassStyleTypes {
  Coupon = 'coupon',
  BoardingPass = 'boardingPass',
  EventTicket = 'eventTicket',
  Generic = 'generic',
  StoreCard = 'storeCard',
}
export const passStyles = [
  PkpassPassStyleTypes.BoardingPass,
  PkpassPassStyleTypes.Coupon,
  PkpassPassStyleTypes.EventTicket,
  PkpassPassStyleTypes.Generic,
  PkpassPassStyleTypes.StoreCard,
];

export enum PkpassBarcodeTypes {
  PKBarcodeFormatAztec = 'PKBarcodeFormatAztec',
  PKBarcodeFormatCode128 = 'PKBarcodeFormatCode128',
  PKBarcodeFormatPDF417 = 'PKBarcodeFormatPDF417',
  PKBarcodeFormatQR = 'PKBarcodeFormatQR',
}

export enum PkpassFieldsType {
  AuxiliaryFields = 'auxiliaryFields',
  BackFields = 'backFields',
  HeaderFields = 'headerFields',
  PrimaryFields = 'primaryFields',
  SecondaryFields = 'secondaryFields',
}

export enum PkpassTransitTypes {
  PKTransitTypeAir = 'PKTransitTypeAir',
  PKTransitTypeBoat = 'PKTransitTypeBoat',
  PKTransitTypeBus = 'PKTransitTypeBus',
  PKTransitTypeGeneric = 'PKTransitTypeGeneric',
  PKTransitTypeTrain = 'PKTransitTypeTrain',
}

export enum PkpassDataDetectorTypes {
  PKDataDetectorTypePhoneNumber = 'PKDataDetectorTypePhoneNumber',
  PKDataDetectorTypeLink = 'PKDataDetectorTypeLink',
  PKDataDetectorTypeAddress = 'PKDataDetectorTypeAddress',
  PKDataDetectorTypeCalendarEvent = 'PKDataDetectorTypeCalendarEvent',
}

export enum PkpassTextAlignTypes {
  PKTextAlignmentLeft = 'PKTextAlignmentLeft',
  PKTextAlignmentCenter = 'PKTextAlignmentCenter',
  PKTextAlignmentRight = 'PKTextAlignmentRight',
  PKTextAlignmentNatural = 'PKTextAlignmentNatural',
}

export enum PkpassDateStyleTypes {
  PKDateStyleNone = 'PKDateStyleNone',
  PKDateStyleShort = 'PKDateStyleShort',
  PKDateStyleMedium = 'PKDateStyleMedium',
  PKDateStyleLong = 'PKDateStyleLong',
  PKDateStyleFull = 'PKDateStyleFull',
}

export enum PkpassNumberStyleTypes {
  PKNumberStyleDecimal = 'PKNumberStyleDecimal',
  PKNumberStylePercent = 'PKNumberStylePercent',
  PKNumberStyleScientific = 'PKNumberStyleScientific',
  PKNumberStyleSpellOut = 'PKNumberStyleSpellOut',
}

export enum ImageTypes {
  background = 'background',
  footer = 'footer',
  icon = 'icon',
  logo = 'logo',
  strip = 'strip',
  thumbnail = 'thumbnail',
}

export const imageTypes = [
  ImageTypes.background,
  ImageTypes.footer,
  ImageTypes.icon,
  ImageTypes.logo,
  ImageTypes.strip,
  ImageTypes.thumbnail,
];

export interface PkpassAssociatedApp {
  idGooglePlay: string;
}

interface PkpassBase {
  organizationName?: string;
  barcodes?: PkpassBarcode[];
  beacons?: PkpassBeacon[];
  location?: PkpassLocation[];
  description?: string;
  associatedStoreIdentifiers?: number[];
  associatedApps?: PkpassAssociatedApp[];
  backgroundColor?: string;
  labelColor?: string;
  foregroundColor?: string;
  logoText?: string;
}

export interface PkpassStyle {
  [PkpassFieldsType.AuxiliaryFields]?: PkpassFields;
  [PkpassFieldsType.BackFields]?: PkpassFields;
  [PkpassFieldsType.HeaderFields]?: PkpassFields;
  [PkpassFieldsType.PrimaryFields]?: PkpassFields;
  [PkpassFieldsType.SecondaryFields]?: PkpassFields;
}

export interface PkpassStyleBoardingPass {
  [PkpassFieldsType.AuxiliaryFields]?: PkpassFields;
  [PkpassFieldsType.BackFields]?: PkpassFields;
  [PkpassFieldsType.HeaderFields]?: PkpassFields;
  [PkpassFieldsType.PrimaryFields]?: PkpassFields;
  [PkpassFieldsType.SecondaryFields]?: PkpassFields;
  transitType: PkpassTransitTypes;
}

export type Pkpass = PkpassBase & {
  [PkpassPassStyleTypes.BoardingPass]?: PkpassStyleBoardingPass;
  [PkpassPassStyleTypes.Coupon]?: PkpassStyle;
  [PkpassPassStyleTypes.EventTicket]?: PkpassStyle;
  [PkpassPassStyleTypes.Generic]?: PkpassStyle;
  [PkpassPassStyleTypes.StoreCard]?: PkpassStyle;
};

export type PkpassFields = PkpassField[];

export interface PkpassField {
  key: string;
  attributedValue?: string | number | Date;
  value?: string | number | Date;
  changeMessage?: string;
  dataDetectorTypes?: PkpassDataDetectorTypes[];
  label?: string;
  textAlignment?: PkpassTextAlignTypes;
  dateStyle?: PkpassDateStyleTypes;
  timeStyle?: PkpassDateStyleTypes;
  ignoresTimeZone?: boolean;
  isRelative?: boolean;
  currencyCode?: string;
  numberStyle?: PkpassNumberStyleTypes;
}

export interface PkpassLocation {
  altitude?: number;
  latitude: number;
  longitude: number;
  relevantText?: string;
}

export interface PkpassBeacon {
  proximityUUID: string;
  major?: number;
  minor?: number;
  relevantText?: string;
}

export interface PkpassBarcode {
  key: string;
  format: string;
  altText?: string;
  message?: string;
  messageEncoding?: string;
}
