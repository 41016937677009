import * as React from 'react';

import BoardingPass from './BoardingPass';
import Coupon from './Coupon';
import EventTicket from './EventTicket';
import Generic from './Generic';
import StoreCard from './StoreCard';
import { usePkpass } from '../usePkpass';

export default function PassStyle(): JSX.Element {
  const pass = usePkpass();

  switch (pass.passStyle) {
    case 'boardingPass':
      return <BoardingPass />;
    case 'coupon':
      return <Coupon />;
    case 'eventTicket':
      return <EventTicket />;
    case 'generic':
      return <Generic />;
    case 'storeCard':
      return <StoreCard />;
    default:
      return <div>Unsupported pass style</div>;
  }
}
