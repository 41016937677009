import * as React from 'react';

import { get as _get } from 'lodash';

import { FieldPreviewLabel } from './FieldPreviewLabel';
import { PkpassFieldsType } from '../../../../../../types';
import { usePkpass } from '../../../usePkpass';
import { FieldPreviewValue } from './FieldPreviewValue';
import { createUseStyles } from 'react-jss';
import { getTextAlign } from './getTextAlign';
import { Theme, useTheme } from '../../../../Theme';

interface BackFieldProps {
  fieldsType: PkpassFieldsType;
  fieldIndex: number;
  style?: React.CSSProperties;
}

export const useStyles = createUseStyles<string, { clickable: boolean, pass: unknown  }, Theme>(
  {
    field: {
      '&:hover': {
        cursor: ({ clickable }) => (clickable ? 'pointer' : undefined),
        opacity: ({ clickable }) => (clickable ? 0.7 : undefined),
      },
    },
  }
);

const BackField = ({ fieldsType, fieldIndex }: BackFieldProps): JSX.Element => {
  const { pass, passStyle, onFieldClick, onFieldDnD } = usePkpass();
  const [dragOver, setDragOver] = React.useState(false);
  const theme: Theme = useTheme();
  const classes = useStyles({ pass, theme, clickable: !!onFieldClick });
  const fieldsPath = `${passStyle}.${fieldsType}`;
  const fieldPath = `${fieldsPath}.${fieldIndex}`;
  const field = _get(pass, `${fieldPath}`);

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(false);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(true);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('droppedFieldsType', fieldsType);
    event.dataTransfer.setData('droppedIndex', `${fieldIndex}`);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedIndex = Number(event.dataTransfer.getData('droppedIndex'));
    const droppedFieldsType = event.dataTransfer.getData(
      'droppedFieldsType'
    ) as PkpassFieldsType;
    onFieldDnD(fieldsType, fieldIndex, droppedFieldsType, droppedIndex);
    setDragOver(false);
  };

  const style: React.CSSProperties = {
    borderTop:
      fieldIndex === 0 ? '1px solid transparent' : '1px solid gainsboro',
    cursor: 'pointer',
    marginLeft: 0,
    marginRight: 0,
    minHeight: 40 * theme.scale,
    minWidth: 20 * theme.scale,
    overflow: 'hidden',
    paddingBottom: 9 * theme.scale,
    paddingTop: 10 * theme.scale,
    textAlign: getTextAlign(field.textAlign),
    zIndex: 100,
    ...(dragOver ? theme.dragOver : {}),
  };

  const content = (
    <React.Fragment>
      <FieldPreviewLabel
        key='fieldPreviewLabel'
        field={field}
        fieldsType={fieldsType}
        labelColor={pass.labelColor}
        theme={theme}
      />
      <FieldPreviewValue
        key='fieldPreviewValue'
        field={field}
        fieldsType={fieldsType}
      />
    </React.Fragment>
  );

  return (
    <div
      key={field.key}
      draggable={!!onFieldDnD}
      onDragOver={onFieldDnD ? onDragOver : undefined}
      onDrop={onFieldDnD ? onDrop : undefined}
      onDragStart={onFieldDnD ? onDragStart : undefined}
      onDragLeave={onFieldDnD ? onDragLeave : undefined}
      className={`${fieldsType} ${classes.field}`}
      style={style}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onFieldClick) {
          onFieldClick({ fieldsType, field, fieldIndex }, event);
        }
      }}
    >
      {content}
    </div>
  );
};

export default BackField;
