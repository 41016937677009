import { Pkpass, PkpassPassStyleTypes, passStyles } from '../../../../types';
import { has } from 'lodash';

export const getPassStyle = (pass: Pkpass): PkpassPassStyleTypes => {
  for (const passStyle of passStyles) {
    if (pass && has(pass, passStyle)) {
      return passStyle;
    }
  }
  return PkpassPassStyleTypes.Generic;
};
