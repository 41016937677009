import * as React from 'react';
import { usePkpass } from '../usePkpass';
import { PkpassField, PkpassFieldsType } from '../../../../types';
import Field from './field/preview/Field';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '../../Theme';

const useStyles = createUseStyles<string, {}, Theme>({
  secondaryAndAuxiliaryFields: {
    padding: ({ theme }) => `${theme.scale * 10}px 0`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: ({ theme }) => theme.scale * 40,
    paddingBottom: ({ theme }) => theme.scale * 10,
    width: ({ theme }) => theme.scale * 320,
  },
});

const SecondaryAndAuxiliaryFields = (): JSX.Element => {
  const { passStyle, pass } = usePkpass();
  const secondaryFields = pass[passStyle].secondaryFields || [];
  const auxiliaryFields = pass[passStyle].auxiliaryFields || [];

  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.secondaryAndAuxiliaryFields}>
      {secondaryFields.map((field: PkpassField, i: number) => {
        return (
          <Field
            key={field.key}
            fieldsType={PkpassFieldsType.SecondaryFields}
            fieldIndex={i}
          />
        );
      })}

      {auxiliaryFields.map((field: PkpassField, i: number) => {
        return (
          <Field
            key={field.key}
            fieldsType={PkpassFieldsType.AuxiliaryFields}
            fieldIndex={i}
          />
        );
      })}
    </div>
  );
};

export default SecondaryAndAuxiliaryFields;
