import * as React from 'react';

import {
  FormatDateOptions,
  FormattedDate,
  FormattedTime,
  IntlProvider,
} from 'react-intl';
import { usePkpass } from '../../../../usePkpass';
import { PkpassDateStyleTypes } from '../../../../../../../types';

const getTimeOptions = (style: PkpassDateStyleTypes): FormatDateOptions => {
  const opts: FormatDateOptions = {};
  opts.hour = '2-digit';
  opts.minute = '2-digit';
  opts.hour12 = false;
  switch (style) {
    case PkpassDateStyleTypes.PKDateStyleShort:
      break;
    case PkpassDateStyleTypes.PKDateStyleMedium:
      opts.second = '2-digit';
      break;
    case PkpassDateStyleTypes.PKDateStyleLong:
      opts.second = '2-digit';
      opts.timeZoneName = 'short';
      break;
    case PkpassDateStyleTypes.PKDateStyleFull:
      opts.second = '2-digit';
      opts.timeZoneName = 'long';
      break;
    default:
      break;
  }
  return opts;
};

const getDateOptions = (style: PkpassDateStyleTypes): FormatDateOptions => {
  const opts: FormatDateOptions = {};
  opts.year = 'numeric';
  switch (style) {
    case PkpassDateStyleTypes.PKDateStyleShort:
      opts.day = '2-digit';
      opts.month = '2-digit';
      break;
    case PkpassDateStyleTypes.PKDateStyleMedium:
      opts.day = 'numeric';
      opts.month = 'short';
      break;
    case PkpassDateStyleTypes.PKDateStyleLong:
      opts.day = 'numeric';
      opts.month = 'long';
      break;
    case PkpassDateStyleTypes.PKDateStyleFull:
      opts.day = 'numeric';
      opts.month = 'long';
      opts.weekday = 'long';
      break;
    default:
      break;
  }
  return opts;
};

interface Props {
  value: string;
  dateStyle: PkpassDateStyleTypes;
  timeStyle: PkpassDateStyleTypes;
}

export default function FieldPreviewValueDate(props: Props): JSX.Element {
  const { language } = usePkpass();
  const { value, dateStyle, timeStyle } = props;
  const isDate = dateStyle !== PkpassDateStyleTypes.PKDateStyleNone;
  const isTime = timeStyle !== PkpassDateStyleTypes.PKDateStyleNone;
  let content = <span />;

  if (isDate && !isTime) {
    content = (
      <FormattedDate value={new Date(value)} {...getDateOptions(dateStyle)} />
    );
  }
  if (!isDate && isTime) {
    content = (
      <FormattedTime value={new Date(value)} {...getTimeOptions(timeStyle)} />
    );
  }
  if (isDate && isTime) {
    content = (
      <span>
        <FormattedDate value={new Date(value)} {...getDateOptions(dateStyle)} />{' '}
        <FormattedTime value={new Date(value)} {...getTimeOptions(timeStyle)} />
      </span>
    );
  }

  return <IntlProvider locale={language}>{content}</IntlProvider>;
}
