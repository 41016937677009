import * as React from 'react';

import { DefaultTheme, createTheming } from 'react-jss';

export type Theme = DefaultTheme & {
  scale: number;
  foregroundColor: string;
  labelColor: string;
  backgroundColor: string;
  dragOver: React.CSSProperties;
};

export const ThemeContext = React.createContext({
  scale: 1,
  foregroundColor: 'rgb(255,255,255)',
  labelColor: 'rgb(255,255,255)',
  backgroundColor: 'rgb(0,0,0)',
  dragOver: {},
});

// Creating a namespaced theming object.
const theming = createTheming<Theme>(ThemeContext);

// Note that `useTheme` here comes from the `theming` object, NOT from `react-jss` import.
export const ThemeProvider = theming.ThemeProvider;
export const useTheme = theming.useTheme;