import * as React from 'react';

import { FormattedNumber, IntlProvider } from 'react-intl';

interface Props {
  value: string | number;
  currency: string;
  language: string;
}

export default function FieldPreviewValueCurrency(props: Props): JSX.Element {
  const { value, currency, language } = props;
  return (
    <IntlProvider locale={language}>
      <FormattedNumber
        {...{ style: 'currency' }}
        currency={currency}
        value={Number(value)}
      />
    </IntlProvider>
  );
}
