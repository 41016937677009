import * as React from 'react';
import {
  PkpassFieldsType,
  PkpassField,
  ILocalizable,
} from '../../../../../../types';
import { usePkpass } from '../../../usePkpass';
import { isObject } from 'lodash';
import { Theme } from '../../../../Theme';
import { createUseStyles } from 'react-jss';

interface Props {
  labelColor: string;
  field: PkpassField;
  fieldsType: PkpassFieldsType;
  theme: Theme;
}

export const useStyles = createUseStyles<string, {labelColor: string}, Theme>({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  field: {
    color: ({ labelColor }) => labelColor,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    fontSize: ({ theme }) => 11 * theme.scale,
  },
  backfield: {
    color: '#7d7d7d',
    fontSize: ({ theme }) => theme.scale * 12,
    marginBottom: ({ theme }) => theme.scale * 4,
  },
});

export const getLabelValue = (
  values: PkpassField,
  language: string
): string | undefined => {
  const label: ILocalizable = values.label;

  if (isObject(label)) {
    return label[language];
  }

  return label;
};

export const FieldPreviewLabel = (props: Props): JSX.Element => {
  const { language } = usePkpass();
  const { fieldsType, field, theme, labelColor } = props;
  const classes = useStyles({ theme, labelColor });

  return (
    <div
      className={
        fieldsType === PkpassFieldsType.BackFields ? undefined : classes.root
      }
    >
      <div
        className={
          fieldsType === PkpassFieldsType.BackFields
            ? classes.backfield
            : classes.field
        }
      >
        {getLabelValue(field, language) || <span>&nbsp;</span>}
      </div>
    </div>
  );
};
