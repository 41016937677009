import * as React from 'react';

import { DesignerFrontSide, PkpassContextProvider, Mask } from './components';
import { PkpassImages, Pkpass } from '../../types';
import {
  FieldClickHandler,
  ImageClickHandler,
  BarcodeClickHandler,
  FieldDnDHandler,
  LogoTextClickHandler,
  BoardingPassIconClickHandler,
} from './types';
import { getInvertColor } from '../../utils/';
import { Theme, ThemeProvider } from './Theme';

export type PkpassPreviewProps = {
  pass: Pkpass;
  images: PkpassImages;
  language?: string;
  scanDisabled?: boolean;
  scale?: number;
  onFieldClick?: FieldClickHandler;
  onFieldDnD?: FieldDnDHandler;
  onImageClick?: ImageClickHandler;
  onBarcodeClick?: BarcodeClickHandler;
  onLogoTextClick?: LogoTextClickHandler;
  onBoardingPassIconClick?: BoardingPassIconClickHandler;
};
export const PkpassPreview = (props: PkpassPreviewProps): JSX.Element => {
  const scale = props.scale || 1;

  const c = getInvertColor(props.pass.backgroundColor);

  const theme: Theme = {
    scale: scale,
    foregroundColor: props.pass.foregroundColor,
    labelColor: props.pass.labelColor,
    backgroundColor: props.pass.backgroundColor,
    dragOver: {
      border: `1px solid ${c}`,
      borderRadius: 5,
      boxShadow: `${c} 0px 0px 15px`,
      opacity: 0.5,
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: 320 * scale,
          height: 450 * scale,
          position: 'relative',
        }}
      >
        <PkpassContextProvider
          pass={props.pass}
          images={props.images}
          scanDisabled={props.scanDisabled}
          onFieldClick={props.onFieldClick}
          onFieldDnD={props.onFieldDnD}
          onImageClick={props.onImageClick}
          onBarcodeClick={props.onBarcodeClick}
          onLogoTextClick={props.onLogoTextClick}
          onBoardingPassIconClick={props.onBoardingPassIconClick}
        >
          <Mask />
          <DesignerFrontSide />
        </PkpassContextProvider>
      </div>
    </ThemeProvider>
  );
};
